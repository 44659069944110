.post {
  &-title {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 30px;
  }

  &-info {
    margin-bottom: 30px;
    color: $primary;

    .fa {
      color: #666;
    }
  }

  &-content {
    font-size: 16px;
    color: #888;
    margin-bottom: 16px;
  }
}

.post-comment {
  border-top: 1px solid $border-color;
  margin-top: 50px;
  padding-top: 50px;

  &__title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 30px;
  }

  &__subtitle {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__desc {
    font-size: 16px;
    color: #888;
    margin-bottom: 30px;
  }

  &__btn {
    font-size: 14px;
    line-height: 50px;
    padding: 0 20px;
  }

  .form-group {
    margin-bottom: 20px;
  }

  .form-control {
    border: 0;
    background-color: #f6f6f6;
    font-size: 12px;
    color: #888;
    padding: 10px 20px;
    border-radius: 0;
  }

  input.form-control {
    height: 50px;
  }

  label {
    font-size: 12px;
    font-weight: 700;
    line-height: 40px;
    padding: 5px;
  }

  @media (max-width: 767px) {
    margin-top: 30px;
    padding-top: 30px;
  }
}
