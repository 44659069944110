.btn {
  &.-default {
    border-color: $border-color;
    background-color: transparent;

    &:hover {
      border-color: $primary;
      color: $primary;
    }
  }

  &.-rounded {
    border-radius: 18px;
  }

  &.-primary {
    @extend .btn-primary;
  }

  &.-primary-o {
    @extend .btn-outline-primary;
  }

  &.-danger {
    @extend .btn-danger;
  }

  &.-danger-o {
    @extend .btn-outline-danger;
  }

  &.-shine {
    @include btn("shine");
  }

  &.-fill {
    @include btn("fill");
  }

  &.fill.-primary {
    &::before {
      background-color: $white;
    }

    &:hover {
      color: $primary;
    }
  }

  &.-fill.-danger {
    &::before {
      background-color: $white;
    }

    &:hover {
      color: $danger;
    }
  }

  &.-fill.-primary-o {
    &::before {
      background-color: $primary;
    }

    &:hover {
      color: $white;
      background-color: $white;
    }
  }

  &.-fill.-danger-o {
    &::before {
      background-color: $danger;
    }

    &:hover {
      color: $white;
      background-color: $white;
    }
  }

  &.-neon-orange {
    @include btn-neon("orange");
  }

  &.-neon-green {
    @include btn-neon("green");
  }

  &.-neon-purple {
    @include btn-neon("purple");
  }

  &.-neon-blue {
    @include btn-neon("blue");
  }

  &.-neon-dark {
    @include btn-neon("dark");
  }
}

$rounded-list: 8 10 18 20 22 24 25 26 28 30;

@each $item in $font-size-list {
  .rounded-#{$item} {
    border-radius: #{$item}px;
  }
}

.button {
  @include btn('shine');
  display: inline-block;
  color: $white;
  text-transform: uppercase;
  background-color: $primary;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  padding: 12px 19px;
  border: 1px solid $primary;
  transition: 0.4s;

  &:hover {
    color: $white;
    background-color: #000;
    border-color: #000;
    text-decoration: none;
  }

  &--lg {
    font-size: 16px;
    padding: 18px 35px;
  }

  &--default {
    background: 0;
    color: #000;
    border: 1px solid $border-color;

    &:hover {
      color: $white;
      background-color: #000;
      border-color: #000;
    }
  }

  &--block {
    display: block;
    text-align: center;
  }
}
