.pagination {
  margin-bottom: 0;

  .page-item {
    &:not(:last-child) {
      margin-right: 4px;
    }
  }

  .page-link {
    display: flex;
    min-width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    border: 0;
    background: 0;
    font-size: 12px;
    color: $gray-600;
    padding: 0 12px;
    border-radius: 0 !important;
    transition: 0.3s;

    &:hover {
      background-color: #000;
      color: $white;
    }
  }

  .page-item.disabled {
    display: none;
  }

  .page-item.active .page-link {
    background-color: #000;
    color: $white;
  }

  @media (max-width: 767px) {
    .page-link {
      min-width: 40px;
      height: 40px;
    }
  }
}
