.banner-slider {
  position: relative;
  z-index: 1;

  &__prev,
  &__next {
    display: flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $white;
    font-size: 30px;
    line-height: 1;
    color: #666;
    position: absolute;
    top: 50%;
    left: 8%;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      box-shadow: 0 1px 2px rgba(#000, 0.1), 0 0 0 3px rgba(#fff, 0.5);
    }

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: 8%;
  }

  &__bg {
    display: block;
    width: 100%;
    min-height: 200px;
    object-fit: cover;
    object-position: 50% 50%;
  }

  &__pagination {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

    .swiper-pagination-bullet {
      width: 14px;
      height: 14px;
      margin: 0 8px;
      opacity: 1;
      background-color: #000;

      &:focus {
        outline: 0;
      }

      &-active {
        background-color: $primary;
        box-shadow: 0 0 0 4px rgba($primary, 0.6);
      }
    }
  }

  &__item {
    position: relative;
    z-index: 1;
  }

  &__overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
  }

  &__title {
    font-size: 84px;
    font-weight: 700;
    color: $white;
    text-align: center;
    line-height: 1.1;
    margin-bottom: 20px;
    text-shadow: 0 1px 10px rgba(#000, 0.1);
    transition: 0s 0.8s;
    opacity: 0;
    transform: translateX(-50vw);
  }

  &__subtitle {
    font-size: 34px;
    color: $white;
    text-align: center;
    line-height: 1.2;
    margin-bottom: 30px;
    text-shadow: 0 1px 10px rgba(#000, 0.1);
    transition: 0s 0.8s;
    opacity: 0;
    transform: translateX(50vw);
  }

  &__desc {
    text-align: center;
    font-size: 24px;
    line-height: 1.2;
    color: $white;
    margin-bottom: 40px;
    text-shadow: 0 1px 10px rgba(#000, 0.1);
    transition: 0s 0.8s;
    opacity: 0;
    transform: translateX(50vw);
  }

  &__btn-wrap {
    transition: 0s 0.8s;
    opacity: 0;
    transform: translateY(-50vw);
  }

  .swiper-slide-active &__title {
    transform: translateX(0);
    opacity: 1;
    transition: 1s 1.2s;
  }

  .swiper-slide-active &__subtitle {
    transform: translateX(0);
    opacity: 1;
    transition: 1s 0.8s;
  }

  .swiper-slide-active &__desc {
    transform: translateX(0);
    opacity: 1;
    transition: 1s 1.6s;
  }

  .swiper-slide-active &__btn-wrap {
    transform: translateY(0);
    opacity: 1;
    transition: 1s 2s;
  }

  &__content--dark &__title {
    color: $primary;
    text-shadow: unset;
  }

  &__content--dark &__subtitle {
    color: #000;
    text-shadow: unset;
  }

  &__content--dark &__desc {
    color: #000;
    text-shadow: unset;
  }

  &__content {
    &--left {
      padding-right: 50%;
    }

    &--right {
      padding-left: 50%;
    }
  }

  @media (min-width: 1200px) {

    &__prev,
    &__next {
      opacity: 0;
      transition: 0.4s;
    }

    &:hover &__prev,
    &:hover &__next {
      opacity: 1;
    }
  }

  @media (max-width: 1399px) {
    &__title {
      font-size: 60px;
      margin-bottom: 20px;
    }

    &__subtitle {
      font-size: 30px;
      margin-bottom: 20px;
    }

    &__desc {
      font-size: 20px;
    }
  }

  @media (max-width: 1199px) {
    &__title {
      font-size: 50px;
      margin-bottom: 16px;
    }

    &__subtitle {
      font-size: 24px;
      margin-bottom: 16px;
    }

    &__desc {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 767px) {

    &__title {
      font-size: 30px;
      margin-bottom: 10px;
    }

    &__subtitle {
      font-size: 16px;
      margin-bottom: 10px;
    }

    &__desc {
      font-size: 14px;
      margin-bottom: 16px;
    }

    &__prev,
    &__next {
      display: none;
    }

    &__pagination {
      bottom: 4px;

      .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
      }
    }
  }

  @media (max-width: 575px) {
    &__title {
      font-size: 20px;
      margin-bottom: 6px;
    }

    &__subtitle {
      font-size: 14px;
      margin-bottom: 6px;
    }

    &__desc {
      font-size: 12px;
      margin-bottom: 10px;
    }

    .button {
      font-size: 12px;
      padding: 6px 20px;
    }
  }
}

.product-slider {
  position: relative;
  z-index: 1;

  &__prev,
  &__next {
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    line-height: 30px;
    cursor: pointer;
    position: absolute;
    z-index: 10;
    top: -80px;
    right: 0;
    color: #888;

    &:hover {
      color: #000;
    }

    &:focus {
      outline: 0;
    }
  }

  &__prev {
    right: 40px;
  }

  @media (max-width: 991px) {

    &__prev,
    &__next {
      top: -65px;
    }
  }
}
