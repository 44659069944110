.cart {
  &__frame {
    @include frame('rect', 124px);
    width: 100px;
    margin-right: 20px;
  }

  &__remove {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 16px;
    position: relative;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 30px;
      border-bottom: 1px solid #000;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  table {
    border-left: 0;
    border-right: 0;
  }

  thead {
    th {
      font-weight: 400;
      font-size: 16px;
      border-bottom: 0;
    }
  }

  tbody {
    td {
      vertical-align: middle;
    }
  }

  &__product {
    width: 350px;
    align-items: center;
  }

  &__name {
    color: #000;
    transition: 0.3s;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  &__info {
    font-size: 12px;
    font-style: italic;
    color: #888;
    margin-top: 8px;

    table {
      border: 0;
    }

    td {
      padding: 0;
      border: 0;
      line-height: 20px;

      &:first-child {
        padding-right: 10px;
      }
    }
  }

  &__price {
    font-size: 24px;
    color: $primary;
  }

  &__quantity {
    width: 80px;
    height: 50px;
    text-align: center;
    font-size: 12px;
    color: #000;
    background-color: #f6f6f6;
    border: 0;
  }
}

.cart-nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 16px;

  &__item {
    font-size: 12px;
    color: $primary;
    transition: 0.3s;

    &:hover {
      color: #000;
      text-decoration: none;
    }

    &+& {
      margin-left: 40px;
    }

    .fa {
      color: #666;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;

    &__item {
      &+& {
        margin-left: 0;
        margin-top: 6px;
      }
    }
  }
}

.cart-summary {
  border: 1px solid $border-color;
  padding: 20px;

  &__title {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  &__info {
    font-size: 20px;
    padding: 20px 0;
    border-top: 1px solid $border-color;
    margin-top: 20px;

    table {
      width: 100%;
    }

    td {
      vertical-align: middle;

      &:first-child {
        text-align: right;
      }

      &:nth-child(2) {
        color: $primary;
        padding-left: 20px;
      }
    }
  }

  &__link {
    display: inline-block;
    border-bottom: 1px solid $primary;
    font-size: 12px;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  &__toggle {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 20px;
    cursor: pointer;

    &::after {
      content: '\f107';
      font-family: fontawesome;
      display: inline-block;
      margin-left: 10px;
      transition: 0.3s;
    }

    &.active::after {
      transform: rotateX(180deg);
    }
  }

  &__body {
    display: none;
    padding: 20px 0;
  }
}
