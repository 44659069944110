.logo {
  display: inline-flex;
  margin: 10px 0;

  img {
    height: 100px;
  }
}

.toppage {
  position: relative;
  z-index: 10;

  &__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px solid $border-color;
    position: relative;
  }

  &__text {
    margin-right: auto;
  }
}

.header {
  position: relative;
  z-index: 100;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__elements {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__btn {
    display: inline-flex;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    position: relative;
    color: $gray-800;
    font-size: 20px;

    &+& {
      margin-left: 4px;
    }

    &:hover {
      color: $primary;
      text-decoration: none;
    }

    span {
      display: inline-flex;
      width: 15px;
      height: 15px;
      justify-content: center;
      align-items: center;
      background-color: $primary;
      border-radius: 50%;
      font-size: 11px;
      font-weight: 700;
      text-align: center;
      color: $white;
      position: absolute;
      top: 0;
      right: 0;
    }

    img {
      max-height: 16px;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  @media (min-width: 1200px) {
    &__btn {
      display: none;
    }
  }
}

.navigation {
  &__inner {
    position: relative;
  }

  @media (min-width: 1200px) {
    &__inner {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.h-cart {
  position: relative;

  &__toggle {
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    line-height: 40px;
    padding: 0 20px;
    cursor: pointer;
  }

  &__quantity {
    display: inline-flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    background-color: #000;
    color: $white;
    border-radius: 50%;
    font-size: 12px;
  }

  &__number {
    font-size: 18px;
    color: #000;
    margin-bottom: 20px;
  }

  &__dropdown {
    display: none;
    min-width: 430px;
    max-width: 100%;
    padding: 45px;
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    background-color: $white;
    box-shadow: 0 5px 27px rgba(0, 0, 0, .09);
  }

  &__label {
    text-align: right;
    font-size: 20px;
    color: #888;
  }

  &__subtotal {
    font-size: 20px;
    text-align: right;
    color: $primary;
    margin-bottom: 24px;
  }

  &__link {
    font-size: 14px;
    color: $primary;
    transition: 0.3s;

    &:hover {
      color: #000;
      text-decoration: none;
    }
  }

  @media (max-width: 767px) {
    position: static;

    &__toggle {
      padding: 0 10px;
    }

    &__dropdown {
      min-width: 100%;
      top: 100%;
    }
  }
}

.search {
  padding-bottom: 15px;

  &__toggle {
    display: none;
  }

  &__close {
    display: none;
  }

  .form-control {
    height: 40px;
    border: 0;
    background-color: #f6f6f6;
    border-radius: 0;
    padding-left: 20px;
    font-size: 12px;
  }

  .input-group-text {
    background-color: #f6f6f6;
    border: 0;
    border-radius: 0;
    font-size: 12px;
    padding-left: 16px;
    padding-right: 16px;
    color: #888;
  }

  @media (min-width: 1200px) {
    padding-bottom: 20px;
    margin-left: 30px;

    &__toggle {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      padding: 0;
      font-size: 18px;
      border: 0;
      background: 0;
    }

    &__body {
      display: none;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: 0 5px 27px rgba(0, 0, 0, .09);
    }

    &__close {
      display: block;
      width: 50px;
      height: 50px;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 100%;

      &::before,
      &::after {
        content: '';
        display: block;
        width: 30px;
        border-bottom: 1px solid #111;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    .form-control {
      height: 50px;
      padding-left: 30px;
      border: 1px solid #ddd;
      border-right: 0;
    }

    .input-group-text {
      border: 1px solid #ddd;
      border-left: 0;
      padding-right: 24px;
    }
  }
}
