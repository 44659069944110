.menu {
  list-style: none;
  margin: 0;
  padding: 0;

  &-item {
    margin: 4px 0 8px;
    position: relative;
  }

  &-link {
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 40px;
    padding: 0 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  &-toggle {
    display: inline-flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;

    span {
      display: inline-flex;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      transition: 0.3s;

      &:hover {
        background-color: rgba(#000, 0.1);
      }
    }

    &.active span {
      transform: rotate(90deg);
    }
  }

  &-sub {
    display: none;
  }

  &-sub &-link {
    padding-left: 40px;
  }

  &-sub-2 &-link {
    padding-left: 60px;
  }

  &-sub-3 &-link {
    padding-left: 80px;
  }

  &-item:hover>&-link {
    background-color: rgba($primary, 0.05);
  }

  &-root>&-item>&-link {
    text-transform: uppercase;
    font-weight: 700;
  }

  &-mega {

    &__desc,
    &__banner-1,
    &__banner-2 {
      display: none;
    }
  }

  @media (min-width: #{$navbar-breakpoint}px) {

    // menu root
    &-root {
      display: flex;
      justify-content: center;
    }

    &-root>&-item {
      margin: 0;

      &:not(:last-child) {
        margin-right: 30px;
      }

      &-mega {
        position: static !important;
      }
    }

    &-root>&-item>&-link {
      padding: 0 0 20px !important;
      position: relative;
      font-size: 16px;

      &::after {
        content: '';
        display: block;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        border-bottom: 3px solid #000;
        transform-origin: left;
        transform: rotateY(90deg);
        transition: 0.4s;
      }
    }

    &-root>&-item-group>&-link {
      padding: 0 30px 20px 0 !important;
    }

    &-root>&-item-mega>&-link {
      position: relative;
      overflow: visible;

      &::before {
        content: '\f107';
        display: inline-flex;
        width: 30px;
        height: 40px;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        font-family: fontawesome;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    &-root>&-item-mega>&-toggle {
      display: none;
    }

    &-root>&-item:hover>&-link {
      background: 0;

      &::after {
        transform: rotateY(0);
      }
    }

    &-root>&-item>&-toggle {
      width: 30px;
      pointer-events: none;

      span {
        transform: rotate(90deg);
      }
    }

    &-root &-sub {
      min-width: 200px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      position: absolute;
      z-index: 2;
      top: -4px;
      left: calc(100% + 4px);
      transform-origin: 0 4px;
      background-color: $white;

      &::before {
        content: "";
        display: block;
        width: 8px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 100%;
      }

      &-right {
        left: auto;
        right: calc(100% + 4px);
        transform-origin: 100% 0;

        &::before {
          right: auto;
          left: 100%;
        }
      }
    }

    &-root &-sub &-link {
      padding-left: 20px;
    }

    &-root &-item-group>&-link {
      padding-right: 40px;
    }

    &-root &-item-group:hover>&-sub {
      display: block;
      animation: menu-float-show 0.2s ease-out;
    }

    &-root>&-item-group>&-sub {
      transform-origin: 0 0;
      top: 100%;
      left: 0;

      &::before {
        width: 100%;
        height: 8px;
        right: auto;
        left: 0;
        top: auto;
        bottom: 100%;
      }
    }

    &-mega {
      display: none;
      grid-auto-flow: row dense;
      grid-gap: 60px;
      grid-template-columns: repeat(8, 1fr);
      width: 100%;
      padding: 0 0 20px;
      padding: 60px;
      background-color: $white;
      position: absolute;
      z-index: 10;
      top: 100%;
      left: 0;
      @include shadow("md");

      // &::before {
      //   content: "";
      //   display: block;
      //   width: 100vw;
      //   height: 100%;
      //   border-top: 1px solid $border-color;
      //   background-color: $white;
      //   position: absolute;
      //   top: 0;
      //   left: 50%;
      //   z-index: -1;
      //   transform: translateX(-50%);
      //   @include shadow("xs");
      // }

      &__desc {
        display: block;
        grid-column: span 2;
        font-size: 16px;
        line-height: 1.5;
      }

      &__banner-1 {
        display: block;
        position: relative;
        grid-column: span 2;
        grid-row: span 2;

        img {
          display: block;
          width: calc(100% + 60px);
          height: calc(100% + 120px);
          max-width: unset;
          position: absolute;
          top: -60px;
          left: 0;
          object-fit: cover;
          object-position: 50% 50%;
        }
      }

      &__banner-2 {
        display: block;
        grid-column: span 3;

        img {
          display: block;
          width: 100%;
        }
      }
    }

    &-item-mega:hover &-mega {
      display: grid;
      animation: menu-fade-in 0.4s;
    }

    &-mega &-sub {
      display: block;
      width: 100%;
      top: auto;
      left: auto;
      right: auto;
      margin: 0;
      position: static;
      background: 0;
      box-shadow: unset;
      grid-column: span 4;
    }

    &-mega &-link {
      white-space: normal;
    }

    &-mega &-toggle {
      display: none;
    }

    &-mega &-item:hover>&-sub {
      animation: none;
    }

    &-mega &-item:hover &-link {
      background: 0;
    }

    &-mega>&-sub {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 60px;
    }

    &-mega>&-sub>&-item {
      margin: 0 0 15px;
      padding-left: 20px;
      position: relative;

      &::before {
        content: '';
        display: block;
        height: 100%;
        border-left: 1px solid $border-color;
        position: absolute;
        top: 0;
        left: -20px;
      }
    }

    &-mega>&-sub>&-item>&-link {
      margin-bottom: 16px;
      font-weight: 700;
      line-height: 24px;
      text-transform: uppercase;
      padding: 0;

      &:hover {
        color: $primary;
      }
    }

    &-mega &-sub &-sub &-link {
      line-height: 20px;
      padding: 6px 20px 6px 40px;
      position: relative;
      left: 0;
      transition: 0.3s;

      &::before {
        content: "➙";
        position: absolute;
        top: 6px;
        left: 20px;
      }

      &:hover {
        left: 10px;
        color: $primary;
      }
    }
  }

  @media (min-width: #{$navbar-breakpoint}px) and (max-width: 1300px) {
    &-full-width>&-item:last-child>&-sub {
      left: auto;
      right: 0;
      transform-origin: 100% 0;
    }
  }

  @media (min-width: #{$navbar-breakpoint}px) and (max-width: 1600px) {
    &-full-width>&-item:last-child>&-sub &-sub {
      left: auto;
      right: calc(100% + 4px);
      transform-origin: 100% 4px;
    }
  }
}

.top-menu {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  border-top: 1px solid $border-color;
  margin-top: 10px;
  padding-top: 16px;

  &__link {
    padding: 0 20px;
    font-size: 12px;
    line-height: 40px;
    color: $primary;

    &:hover {
      color: $primary;
      text-decoration: none;
    }

    .fa {
      color: #888;
    }
  }

  @media (min-width: 1200px) {
    display: flex;
    position: absolute;
    z-index: 20;
    top: -160px;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 0;
    margin-top: 0;
    border-top: 0;

    &__item {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    &__link {
      white-space: nowrap;
    }
  }
}
