.card-1 {
  position: relative;
  z-index: 1;

  &__frame {
    @include frame('rect', 158%);
  }

  &__overlay {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    padding: 50px;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
  }

  &__body {
    background-color: rgba(59, 242, 188, .8);
    text-align: center;
    color: $white;
    padding: 70px 30px;
    flex-grow: 1;
  }

  &__text-1 {
    font-size: 84px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 10px;
  }

  &__text-2 {
    font-size: 24px;
    text-transform: uppercase;
  }

  &__text-3 {
    font-size: 54px;
    font-weight: 700;
    margin-bottom: 30px;
  }

  @media (max-width: 1300px) {
    &__overlay {
      padding: 30px;
    }

    &__text-1 {
      font-size: 60px;
    }

    &__text-2 {
      font-size: 20px;
    }

    &__text-3 {
      font-size: 40px;
    }
  }

  @media (max-width: 1199px) {
    &__overlay {
      padding: 20px;
    }

    &__body {
      padding: 30px 20px;
    }

    &__text-1 {
      font-size: 50px;
      margin-bottom: 8px;
    }

    &__text-2 {
      font-size: 18px;
      margin-bottom: 10px;
    }

    &__text-3 {
      font-size: 30px;
      margin-bottom: 20px;
    }
  }
}

.card-2 {
  position: relative;
  z-index: 1;

  &__frame {
    @include frame('rect', 158%);
  }

  &__body {
    padding: 30px 10px 0;
    background-color: $white;
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 50px;
    right: 50px;
    text-align: center;
  }

  &__title {
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  &__link {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: $primary;
    transition: 0.3s;

    &:hover {
      color: $dark;
      text-decoration: none;
    }
  }

  @media (max-width: 1300px) {
    &__title {
      font-size: 20px;
    }
  }

  @media (max-width: 1199px) {
    &__body {
      left: 20px;
      right: 20px;
    }

    &__title {
      font-size: 18px;
    }
  }
}

.card-3 {
  position: relative;
  z-index: 1;

  &__frame {
    @include frame('rect', 66.66%);
  }

  &__overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 90px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }

  &__title {
    color: $white;
    margin-bottom: 20px;
    font-size: 24px;

    strong {
      font-size: 35px;
      font-weight: 700;
    }
  }

  @media (max-width: 1199px) {
    &__overlay {
      padding: 40px;
    }

    &__title {
      font-size: 20px;

      strong {
        font-size: 30px;
      }
    }
  }

  @media (max-width: 575px) {
    &__overlay {
      padding: 35px;
    }

    &__title {
      font-size: 16px;

      strong {
        font-size: 22px;
      }
    }
  }
}

.product {
  &__wrapper {
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
  }

  &__frame {
    @include frame('rect', 124%);
    @include frame('shine');
    margin-bottom: 30px;
  }

  &__price {
    font-size: 16px;
    color: #888;
    text-align: center;
    margin-bottom: 8px;

    span {
      color: $primary;
      font-size: 24px;

      &:not(:first-child) {
        margin-left: 4px;
      }
    }

    del {
      margin-left: 15px;
    }
  }

  &__timer {
    width: 100%;
    z-index: 10;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &__name {
    @include max-line(2);
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    color: #000;
    margin-bottom: 15px;

    a:hover {
      color: #000;
      text-decoration: none;
    }
  }

  &__colors {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;

    .color-picker__item {
      &:not(:last-child) {
        margin-right: 8px;
      }

      span {
        width: 24px;
        height: 24px
      }
    }
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  &__stars {
    font-size: 18px;
  }

  &__review {
    font-size: 12px;
    margin-left: 6px;

    &,
    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  &__btns {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  &__btn {
    display: inline-flex;
    font-size: 24px;
    color: #999;

    &:hover {
      color: #000;
      text-decoration: none;
    }

    &:not(:last-child) {
      margin-right: 28px;
    }

    img {
      height: 24px;
    }
  }
}

.product-2 {
  &+& {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $border-color;
  }

  &__frame {
    @include frame('rect', 124%);
    @include frame('shine');
    width: 100px;
    margin-right: 20px;
  }

  &__name {
    @include max-line(2);
    color: #000;
    margin-bottom: 4px;

    a {
      color: #000;
    }

    a:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  &__price {
    color: #888;
    font-size: 12px;

    span {
      color: $primary;
      font-size: 16px;
    }
  }

  &__stars {
    font-size: 18px;
  }

  &__review {
    font-size: 12px;
  }
}

.product-3 {
  &__frame {
    @include frame('rect', 124%);
    margin: 0 auto;
    max-width: 100%;
    width: 110px;
    margin-bottom: 10px;
    margin-bottom: 16px;
  }

  &__name {
    font-size: 16px;
    color: #000;
    transition: 0.3s;
    margin-bottom: 4px;

    a {
      color: #000;
    }

    a:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  &__stars {
    font-size: 18px;
  }

  &__review {
    font-size: 12px;
    color: #000;
  }

  &__price {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #888;
    text-align: center;
    line-height: 30px;
    margin-top: 16px;

    span {
      color: $primary;
      font-size: 20px;
      margin-left: 4px;
    }
  }

  &__btn {
    font-size: 20px;
    color: #888;

    &:hover {
      color: #000;
      text-decoration: none;
    }
  }
}

.product-4 {
  border-bottom: 1px solid $border-color;
  margin-bottom: 30px;
  padding-bottom: 30px;

  &__frame {
    @include frame('rect', 100%);
    width: 75px;
    margin-right: 20px;
  }

  &__name {
    color: #000;
    transition: 0.3s;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  &__toggle {
    display: inline-block;
    font-size: 12px;
    color: #000;
    cursor: pointer;

    &::after {
      content: '\f107';
      font-family: fontawesome;
      margin-left: 8px;
      transition: 0.3s;
    }

    &.active::after {
      transform: rotateY(180deg);
    }
  }

  &__detail {
    display: none;
    font-size: 12px;
    line-height: 20px;
    color: #888;
    padding: 4px 0 10px;

    table {
      width: 100%;

      td {
        &:first-child {
          padding-right: 10px;
        }
      }
    }
  }

  &__price {
    font-size: 20px;
    color: $primary;
    margin-bottom: 16px;
  }

  &__quantity {
    width: 85px;
    height: 50px;
    background-color: #f6f6f6;
    text-align: center;
    border: 0;
    font-size: 12px;
    color: #000;
  }

  &__remove {
    display: inline-flex;
    width: 24px;
    height: 24px;
    margin-left: 10;
    position: relative;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 24px;
      border-bottom: 1px solid #000;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
}

.news {
  &__title {
    font-size: 26px;
    color: #000;
    margin-bottom: 30px;
  }

  &__frame {
    @include frame('rect', 60%);
    margin-bottom: 30px;
  }

  &__desc {
    @include max-line(2);
    margin-bottom: 24px;
    font-size: 16px;
    color: #888;
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  &__info {
    color: $primary;

    .fa {
      color: #666;
    }
  }

  &__btn {
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    padding: 9px 19px;
    border: 1px solid #ccc;
    color: #000;
    font-weight: 700;
    text-transform: uppercase;

    &:hover {
      border-color: #000;
      background-color: #000;
      color: $white;
      text-decoration: none;
    }
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 20px;
      margin-bottom: 16px;
    }

    &__frame {
      margin-bottom: 16px;
    }

    &__desc {
      margin-bottom: 16px;
    }

    &__info {
      flex: 0 0 100%;
      margin-bottom: 16px;
    }
  }
}

.review {
  border-bottom: 1px solid $border-color;
  padding-bottom: 40px;
  margin-bottom: 40px;

  &__summary {
    font-size: 20px;
    margin-bottom: 12px;
  }

  &__rating {
    margin-bottom: 8px;

    .stars {
      font-size: 20px;
    }
  }

  &__rating-label {
    font-size: 12px;
    color: #888;
    min-width: 70px;
  }

  &__review {
    font-size: 16px;
    color: #888;
    margin-top: 16px;
  }

  &__info {
    margin-top: 12px;
  }
}
