.section {
  padding: 90px 0;

  &+& {
    border-top: 1px solid $border-color;
  }

  &__title {
    font-size: 35px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 40px;

    &--custom {
      padding: 0 64px;
    }
  }

  @media (max-width: 991px) {
    padding: 60px 0;

    &__title {
      font-size: 30px;
      margin-bottom: 30px;
    }
  }

  @media (max-width: 767px) {
    padding: 40px 0;

    &__title {
      font-size: 26px;

      &--custom {
        text-align: left;
        padding-left: 0;
      }
    }
  }
}

.section-2 {
  &__title {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 30px;
  }

  @media (max-width: 1199px) {
    &__title {
      font-size: 30px;
    }
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 24px;
    }
  }
}

.section-3 {
  margin-bottom: 40px;

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  &__title {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
  }
}

.section-4 {
  max-width: 885px;
  margin: 0 auto;

  &__block {
    &:not(:last-child) {
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid $border-color;
    }
  }

  &__title {
    font-size: 35px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 35px;
  }

  &__subtitle {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  &__desc {
    font-size: 16px;
    color: #888;
    margin-bottom: 16px;
  }
}

.aside {
  &+& {
    margin-top: 50px;
    padding-top: 50px;
    border-top: 1px solid $border-color;
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 30px;
  }

  &__subtitle {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  &__empty {
    font-size: 12px;
    color: #888;
    margin-top: 16px;
  }

  @media (max-width: 767px) {
    margin-top: 30px;
    padding-top: 30px;
  }
}
