.footer {
  padding-top: 90px;
  border-top: 1px solid $border-color;

  &__contact {
    border-top: 1px solid $border-color;
    text-align: center;
    font-size: 12px;
    padding: 40px 0;
    margin-top: 100px;
  }

  @media (max-width: 1199px) {
    padding-top: 60px;

    &__contact {
      padding: 30px 0;
      margin-top: 60px;
    }
  }
}

.f-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  padding-left: 0;
  max-width: 1024px;
  margin: 0 auto;

  &__item {
    padding: 4px 10px;
  }

  &__link {
    display: inline-block;
    font-size: 12px;
    color: #000;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
}

.f-contact {
  display: flex;
  justify-content: center;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 30px;
  font-size: 12px;
  color: #888;

  &__item {
    padding: 8px 0;

    &:not(:last-child) {
      border-right: 1px solid $border-color;
      margin-right: 25px;
      padding-right: 25px;
    }

    a {
      color: inherit;

      &:hover {
        color: inherit;
        text-decoration: none;
      }
    }
  }

  @media (max-width: 1199px) {
    display: block;
    max-width: 350px;
    margin: 30px auto 0;

    &__item {
      &:not(:last-child) {
        margin-right: 0;
        padding-right: 0;
        border-right: 0;
      }
    }
  }
}

.f-social {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding-left: 0;
  margin-top: 40px;
  margin-bottom: 0;

  &__item {
    &:not(:last-child) {
      margin-right: 14px;
    }
  }

  &__link {
    display: flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid $border-color;
    color: #666;
    font-size: 16px;

    &:hover {
      color: $white;
      background-color: #000;
      text-decoration: none;
    }
  }
}
