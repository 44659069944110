.news-list {
  &__item {
    border-bottom: 1px solid $border-color;
    margin-bottom: 50px;
    padding-bottom: 50px;
  }

  @media (max-width: 991px) {
    &__item {
      margin-bottom: 30px;
      padding-bottom: 30px;
    }
  }
}
