.md-video {
  &__body {
    padding: 0;
    position: relative;
  }

  &__close {
    display: flex;
    width: 30px;
    height: 30px;
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    color: $white;
    background-color: $black;
    border: 2px solid $border-color;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(#000, 0.3);

    &::before,
    &::after {
      content: '';
      display: block;
      width: 14px;
      height: 2px;
      background-color: $white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotateZ(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotateZ(-45deg);
    }
  }

  @media (min-width: 1199px) {
    .modal-dialog {
      max-width: 1020px;
    }
  }
}

.md-compare {
  &__table {
    text-align: center;
    font-size: 12px;
    color: #888;

    .table {
      margin-bottom: 0;
    }
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px 20px;
    position: relative;
    background-color: $white;
  }

  &__close {
    font-size: 12px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    border: 0;
    background: 0;
    padding: 0;
    display: none;
  }

  &__print {
    color: #000;
    transition: 0.3s;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  .modal-body {
    padding: 0;
  }

  .table {
    td {
      &:not(:first-child) {
        min-width: 250px;
      }
    }
  }

  @media (max-width: 991px) {
    &__close {
      display: inline-block;
    }
  }

  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 1400px;
    }
  }
}

.md-announcement {
  &__title {
    font-size: 18px;
    margin-bottom: 4px;
    margin-right: 20px;
  }

  &__subtitle {
    color: #999;
  }

  &__close {
    display: flex;
    justify-content: center;
    background: 0;
    border: 0;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    color: $primary;
  }

  .modal-header {
    display: block;
    position: relative;
  }
}

.md-form {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px 0;
    margin-bottom: 15px;
  }

  &__title {
    font-size: 20px;
    margin: 0;
    text-transform: capitalize;
  }

  &__switch {
    font-size: 16px;
    color: $danger;

    &:hover {
      color: $danger;
      text-decoration: none;
    }
  }

  &__sendcode {
    &:hover {
      text-decoration: none;
    }
  }

  &__btns {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  }

  &__btn {
    @include btn('shine');
    display: inline-block;
    min-width: 140px;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    padding: 0 10px;
    line-height: 35px;
    border-radius: 2px;
    margin-left: 8px;
    color: $gray-800;
    transition: .3s;
    border: 0;
    background: #fff;

    &:hover {
      color: $white;
      background-color: #000;
      text-decoration: none;
    }

    &--primary {
      background: $primary;
      color: $white;
    }
  }

  &__helper {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    &-btn {
      color: $gray-600;

      &:hover {
        color: $gray-600;
        text-decoration: none;
      }

      &:not(:first-child) {
        margin-left: 30px;
        position: relative;

        &::before {
          content: '';
          display: block;
          width: 1px;
          height: 10px;
          border-left: 1px solid $border-color;
          position: absolute;
          top: 0;
          left: -15px;
        }
      }
    }
  }

  &__footer {
    padding: 10px;
    background-color: $light;
  }

  &__social {
    display: flex;
    height: 32px;
    align-items: center;
    background-color: $white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 13px;
    color: $gray-800;

    &:hover {
      color: $gray-800;
      text-decoration: none;
    }

    img {
      height: 18px;
      margin-right: 4px;
    }

    span {
      flex-grow: 1;
      text-align: center;
    }

    &--facebook {
      background-color: $facebook;
      color: $white;

      &:hover {
        color: $white;
      }
    }
  }

  input.form-control {
    height: 40px;
  }
}
