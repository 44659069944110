$gutters: 4 8 10 16 20 30 40 50 60 80 100;

.gutter {
  @each $gutter in $gutters {
    &.-all-#{$gutter} {
      margin: 0 -#{$gutter / 2}px;

      &>[class^="col"] {
        padding: 0 #{$gutter / 2}px;
      }
    }
  }

  @each $gutter in $gutters {
    @each $breakpoint in map-keys($grid-breakpoints) {
      @include media-breakpoint-up($breakpoint) {
        &.-over-#{$breakpoint}-#{$gutter} {
          margin: 0 -#{$gutter / 2}px;

          &>[class^="col"] {
            padding: 0 #{$gutter / 2}px;
          }
        }
      }

      @include media-breakpoint-down($breakpoint) {
        &.-under-#{$breakpoint}-#{$gutter} {
          margin: 0 -#{$gutter / 2}px;

          &>[class^="col"] {
            padding: 0 #{$gutter / 2}px;
          }
        }
      }
    }
  }
}

// add column 20% (5 items in a row)
.col-20 {
  flex: 0 0 20%;
  max-width: 20%;
  padding: 0 15px;
}

@each $key,
$val in $grid-breakpoints {
  .col-#{$key}-20 {
    padding: 0 15px;
  }

  @media (min-width: #{$val}) {
    .col-#{$key}-20 {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
}

.grid {
  &__main {
    margin-bottom: 40px;
  }

  @media (min-width: 991px) {
    display: flex;
    align-items: flex-start;

    &__main {
      flex: 0 0 calc(100% - 300px);
      max-width: calc(100% - 300px);
      order: 1;
      padding-left: 15px;
      margin-bottom: 0;
    }

    &__aside {
      flex: 0 0 300px;
      max-width: 300px;
      border-right: 1px solid $border-color;
      padding-right: 15px;
    }
  }

  @media (min-width: 1400px) {
    &__main {
      padding-left: 50px;
    }

    &__aside {
      padding-right: 50px;
    }
  }
}

.grid-2 {
  &__main {
    margin-bottom: 40px;
  }

  @media (min-width: 992px) {
    display: flex;
    justify-content: space-between;

    &__main {
      flex: 0 0 calc(100% - 380px);
      max-width: calc(100% - 380px);
      margin-bottom: 0;
    }

    &__aside {
      flex: 0 0 350px;
      max-width: 350px;
    }
  }

  @media (min-width: 1200px) {
    &__main {
      flex: 0 0 calc(100% - 480px);
      max-width: calc(100% - 480px);
    }

    &__aside {
      flex: 0 0 450px;
      max-width: 450px;
    }
  }
}

.product-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -25px;
  margin-bottom: 50px;

  &__col {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 50px 25px;
    border-bottom: 1px solid $border-color;
  }

  @media (max-width: 767px) {
    margin-bottom: 30px;
    margin: 0 -15px;

    &__col {
      padding: 30px 15px;
    }
  }

  @media (max-width: 575px) {
    &__col {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
