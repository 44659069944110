.breadcrumb {
  font-size: 12px;
  line-height: 20px;
  padding: 15px 0;

  &-item {

    &,
    &.active,
    a,
    a:hover {
      color: $primary;
      text-decoration: none;
    }

    a {
      color: #000;
    }

    &::before {
      color: #999 !important;
    }
  }
}

.breadcrumb-wrapper {
  background-color: $light;
}
