.subscribe {
  padding: 90px 0;
  background-color: $primary;
  margin-bottom: -1px;
  position: relative;
  z-index: 10;

  &__title {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 8px;
    color: $white;
    line-height: 1.2;
  }

  &__desc {
    font-size: 16px;
    color: $white;
  }

  &__btn {
    @include btn('shine');
    display: inline-block;
    line-height: 48px;
    border: 1px solid $white;
    padding: 0 20px;
    color: $white;
    background: 0;
    font-size: 16px;
    margin-left: 10px;
  }

  .form-control {
    height: 50px;
    border: 1px solid $white;
    border-left: 0;
    border-radius: 0;
    color: $white;
    padding-left: 0;
    background: 0;

    &::placeholder {
      color: $white;
    }
  }

  .input-group-text {
    border: 1px solid $white;
    border-right: 0;
    border-radius: 0;
    color: $white;
    background: 0;
    font-size: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }

  @media (max-width: 767px) {
    padding: 60px 0;

    &__title {
      font-size: 24px;
    }

    &__desc {
      font-size: 14px;
    }

    &__btn {
      font-size: 14px;
    }
  }
}

.promotion {
  text-align: center;

  &__label {
    font-size: 24px;
  }

  &__title {
    font-size: 45px;
    font-weight: 700;
    color: $primary;
    margin-bottom: 20px;
    line-height: 1.2;
  }

  &__desc {
    font-size: 16px;
    max-width: 680px;
    margin: 0 auto 40px;
  }

  @media (max-width: 1199px) {
    &__title {
      font-size: 35px;
      margin-bottom: 16px;
    }

    &__desc {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 575px) {
    &__label {
      font-size: 18px;
    }

    &__title {
      font-size: 28px;
    }

    &__desc {
      font-size: 14px;
    }
  }
}

.brands {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 30px;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  &__frame {
    @include frame('rect', 66.66%);

    img {
      object-fit: contain;
    }
  }

  @media (max-width: 1199px) {
    grid-gap: 20px;
  }

  @media (max-width: 991px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.timer {
  display: flex;
  justify-content: center;
  background-color: $primary;
  color: $white;
  padding: 10px;

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;

    &:not(:last-child) {
      margin-right: 25px;
      position: relative;

      &::after {
        content: ':';
        position: absolute;
        top: 500%;
        right: -13px;
        transform: translateY(-50%);
      }
    }

    span {
      &:nth-child(2) {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
}

.product-info {
  &__label {
    color: $primary;
    font-size: 12px;
    line-height: 24px;
    margin-bottom: 20px;
    color: #888;
  }

  &__label-2 {
    font-size: 12px;
    width: 80px;
    color: #888;
  }

  &__quantity {
    width: 50px;
    height: 50px;
    font-size: 12px;
    text-align: center;
    background-color: #f6f6f6;
    border: 0;
  }

  &__title {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 25px;
  }

  &__price {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 16px;
    color: #888;
    margin-bottom: 16px;

    span {
      color: $primary;
      font-size: 26px;
      font-weight: 700;
    }
  }

  &__desc {
    font-size: 16px;
    color: #888;
    margin-bottom: 20px;
  }

  &__btns {
    display: flex;
    margin-top: 16px;
  }

  &__btn {
    font-size: 24px;
    color: #888;

    &:hover {
      color: #000;
      text-decoration: none;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  &__review {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 24px;

    .stars {
      font-size: 18px;
    }

    a {
      font-size: 12px;
      color: $primary;

      &:hover {
        color: $primary;
        text-decoration: none;
      }

      &:not(:first-child) {
        margin-left: 12px;
      }
    }
  }
}

.detail-tabs {
  border-bottom: 1px solid $border-color;
  padding-bottom: 20px;
  margin-bottom: 30px;

  .nav-item {
    &:not(:last-child) {
      margin-right: 40px;
    }
  }

  .nav-link {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: #000;
    padding: 10px 0;

    &.active {
      color: $primary;
    }
  }

  @media (max-width: 767px) {
    padding-bottom: 16px;
    margin-bottom: 20px;
  }
}

.detail-content {
  font-size: 16px;
  color: #888;

  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.more-product-info {
  font-size: 16px;

  table {
    margin-bottom: 0;

    td {
      vertical-align: middle;
      padding: 5px 0 5px 50px;
      color: #888;

      &:first-child {
        white-space: nowrap;
        border-right: 1px solid $border-color;
        padding-right: 50px;
        padding-left: 0;
        color: #000;
      }
    }
  }

  @media (max-width: 767px) {
    font-size: 14px;

    table {
      td {
        padding-left: 20px;

        &:first-child {
          padding-right: 20px;
          padding-left: 0;
        }
      }
    }
  }
}

.review-form {
  padding-top: 24px;

  &__title {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  &__rating {
    display: flex;
    align-items: center;

    &+& {
      margin-top: 4px;
    }
  }

  &__rating-label {
    min-width: 65px;
    font-size: 12px;
    color: #888;
  }

  table {
    width: 100%;
    max-width: 888px;

    td {
      font-size: 12px;
      color: #888;
      vertical-align: middle;
      padding: 15px 0;

      &:first-child {
        white-space: nowrap;
        padding-right: 20px;
      }

      .form-control {
        border: 0;
        background-color: #f6f6f6;
        font-size: 12px;
        color: #888;
        padding: 10px 20px;
        border-radius: 0;
      }

      input.form-control {
        height: 50px;
      }

      label {
        font-size: 12px;
        font-weight: 700;
        line-height: 40px;
        padding: 5px;
      }
    }
  }
}

.tool {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $border-color;
  margin-bottom: 40px;
  padding-bottom: 40px;

  &__group {
    display: flex;
    align-items: center;

    .custom-select {
      width: auto;
      border: 0;
      background-color: #f6f6f6;
      border-radius: 0;
      height: 50px;
      padding-left: 20px;
      padding-right: 50px;

      &:focus {
        outline: 0;
        box-shadow: unset;
        border: 0;
      }
    }
  }

  &__label {
    font-size: 12px;
    font-weight: 700;
    margin-right: 12px;
    white-space: nowrap;
  }

  &__direction {
    display: inline-block;
    position: relative;
    margin-bottom: 0;
    font-size: 18px;
    color: #666;
    margin-left: 12px;
    cursor: pointer;

    input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
    }

    .fa-sort-amount-asc {
      display: none;
    }


    input:checked~.fa-sort-amount-asc {
      display: inline-block;
    }

    input:checked~.fa-sort-amount-desc {
      display: none;
    }
  }

  &__content {
    font-size: 12px;
    text-align: center;
    flex-grow: 1;
    padding: 0 20px;
  }
}

.filter {
  &__block {
    &:not(:last-child) {
      margin-bottom: 30px;
    }

    &--light {
      background-color: $light;
      padding: 20px 8px 20px 20px;
    }
  }

  &__selected {
    font-size: 12px;
    line-height: 20px;
    color: #888;
    margin-top: 12px;

    a {
      font-size: 16px;
      color: #000;
      margin-left: 4px;
      position: relative;
      top: 1px;

      &:hover {
        color: $primary;
        text-decoration: none;
      }
    }
  }

  &__clear {
    display: inline-block;
    font-size: 12px;
    line-height: 20px;
    margin-top: 12px;
    color: $primary;
    text-decoration: underline;

    &:hover {
      color: #000;
      text-decoration: underline;
    }
  }

  &__header {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;

    &::after {
      content: '\f106';
      display: inline-block;
      font-family: fontawesome;
      font-weight: 400;
      margin-left: 8px;
      transition: 0.4s;
    }

    &.active::after {
      transform: rotateX(180deg);
    }
  }

  &__body {
    padding-top: 4px;
  }

  &__item {
    display: block;
    position: relative;
    padding-right: 20px;
    color: #888;
    margin-top: 12px;
    font-size: 12px;
    margin-bottom: 0;
    position: relative;
    cursor: pointer;

    input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }

    input:checked~span {
      font-weight: 700;
      color: #000;
    }

    &:hover {
      color: $primary;
      text-decoration: none;
    }

    span:last-child {
      color: #aaa;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__colors {
    padding-top: 10px;

    .color-picker__item {
      &:not(:last-child) {
        margin-right: 8px;
      }

      span {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.as-compare {
  &__item {
    position: relative;
    padding-right: 20px;
    font-size: 16px;
    line-height: 24px;

    &+& {
      margin-top: 16px;
    }
  }

  &__title {
    color: #000;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  &__remove {
    font-size: 20px;
    line-height: 24px;
    color: #000;
    position: absolute;
    top: 0;
    right: 0;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  &__footer {
    display: flex;
    margin-top: 20px;

    .button {
      display: block;
      flex-grow: 1;
      text-align: center;

      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}

.login-btn {
  @include btn('shine');
  display: flex;
  background-color: $facebook;
  color: $white;
  align-items: center;
  border-radius: 2px;
  overflow: hidden;

  &:hover {
    color: $white;
    text-decoration: none;
  }

  .fa {
    display: flex;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    border-right: 1px solid rgba(#fff, 0.5);
    margin-right: 24px;
  }

  &--twitter {
    background-color: $twitter;
  }
}

.register-form {
  .form-group {
    margin-bottom: 20px;
  }

  .form-control {
    background-color: #f6f6f6;
    border-radius: 0;
    font-size: 12px;
    padding: 12px 20px;
    border: 0;
  }

  input.form-control {
    height: 50px;
  }

  label.col-form-label {
    font-size: 12px;
    font-weight: 700;
    line-height: 40px;
    padding: 5px;
  }
}

.content-wrapper {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 20px;
    position: absolute;
    bottom: 100%;
    left: 0;
    box-shadow: 0 3px 10px rgba(#000, 0.1);
  }
}

.shipping-method {
  thead {
    th {
      border: 0;
      background-color: $light;
      padding: 6px 12px;
      font-weight: 400;
      font-size: 16px;
    }
  }
}

.order-summary {
  border: 1px solid $border-color;
  padding: 20px;

  &__title {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 24px;
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 700;
    padding-right: 20px;
    text-transform: uppercase;
    position: relative;
  }

  &__edit {
    position: absolute;
    right: 0;
  }

  &__toggle {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    padding-right: 24px;
    position: relative;
    cursor: pointer;

    &::after {
      content: '\f107';
      font-family: fontawesome;
      display: flex;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      transition: 0.3s;
      position: absolute;
      top: 0;
      right: 0;
    }

    &.active::after {
      transform: rotateX(180deg);
    }
  }

  &__body {
    display: none;
    padding-top: 30px;
  }

  &__info {
    font-size: 20px;
    color: $primary;
  }

  &__detail {
    font-size: 12px;
    line-height: 30px;
    color: #888;
  }

  &__group {
    &:not(:last-child) {
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid $border-color;
    }
  }
}

.payment-tabs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin-bottom: 40px;

  &__item {
    font-size: 26px;
    font-weight: 700;
    padding-bottom: 35px;
    position: relative;
    color: #888;

    &::before {
      content: '';
      display: block;
      width: 100%;
      border-bottom: 1px solid $border-color;
      position: absolute;
      bottom: 15px;
      left: 0;
    }

    &::after {
      content: '';
      display: block;
      width: 33px;
      height: 33px;
      border-radius: 50%;
      border: 2px solid #888;
      background-color: $white;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    &.active {
      color: #000;
    }

    &.active::before {
      border-bottom-color: $primary;
    }

    &.active::after {
      border-color: $primary;
      background-color: $primary;
      box-shadow: inset 0 0 0 8px $white;
    }
  }

  @media (max-width: 1199px) {
    grid-gap: 20px;

    &__item {
      font-size: 18px;
      padding-bottom: 22px;

      &::before {
        bottom: 10px;
      }

      &::after {
        width: 21px;
        height: 21px;
      }

      &.active::after {
        box-shadow: inset 0 0 0 4px $white;
      }
    }
  }
}

.payment-sign-in {
  display: inline-block;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 700;
  color: #888;
  text-transform: uppercase;
  transition: 0.3s;
  padding: 18px 0 17px;

  &:hover {
    color: $primary;
    text-decoration: none;
  }
}

.payment-method {
  background-color: #fafafa;
  border: 1px solid $border-color;
  padding: 24px;

  &__title {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    margin-bottom: 16px;
    position: relative;

    &::before {
      content: '';
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: $primary;
      border: 1px solid $primary;
      position: absolute;
      top: 1px;
      left: -18px;
      box-shadow: inset 0 0 0 4px #fafafa;
    }
  }

  &__info {
    font-size: 12px;
    color: #888;
    line-height: 30px;
    margin-bottom: 20px;
  }
}


.as-info {
  align-items: center;
  border-bottom: 1px solid $border-color;
  padding-bottom: 16px;
  margin-bottom: 20px;

  &__frame {
    @include frame('circle');
    width: 50px;
    margin-right: 20px;
  }

  &__name {
    font-weight: 600;
  }

  &__edit {
    color: $gray-800;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
}

.acc-menu {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;

  &__item {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__sub &__item {
    margin-top: 8px;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  &__link {
    display: inline-block;
    font-size: 14px;
    line-height: 25px;
    color: $gray-800;

    &.active,
    &:hover {
      color: $primary;
      text-decoration: none;
    }

    img {
      width: 25px;
      height: 25px;
      margin-right: 15px;
      object-fit: cover;
      object-position: 50% 50%;
    }

    .fa {
      display: inline-flex;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      color: $white;
      background-color: #000;
      font-size: 12px;
      line-height: 24px;
    }
  }

  &__sub {
    list-style: none;
    margin: 0;
    padding-left: 40px;
  }
}



.acc-form {
  &--short {
    max-width: 550px;
    margin: 0 auto;
  }

  &__label {
    text-align: right;
    color: #757575;
    padding-top: 4px;
    text-transform: capitalize;
  }

  &__text {
    padding: 4px 0;

    a {

      &,
      &:hover {
        color: #2e74b9;
        text-decoration: underline;
      }
    }
  }
}

.acc-section {
  height: 100%;
  overflow: hidden;
  background-color: $white;
  border-radius: 4px;
  @include shadow('xs');

  &__header {
    padding: 0 30px;

    &-inner {
      display: flex;
      flex-wrap: wrap;
      padding: 20px 0;
      border-bottom: 1px solid $border-color;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__body {
    padding: 30px;
  }

  &__title {
    font-size: 18px;
    text-transform: capitalize;
    margin-bottom: 0;
  }

  &__subtitle {
    color: #666;
  }
}

.acc-grid {
  display: flex;
  align-items: flex-start;

  &__main {
    flex: 1;
    padding-right: 50px;
  }

  &__aside {
    flex: 0 0 280px;
    border-left: 1px solid $border-color;
    padding: 16px 50px 0;
  }
}



.address {
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $border-color;
  }

  &__info {
    margin-right: 20px;

    table {
      td {
        padding: 6px 0;

        &:first-child {
          white-space: nowrap;
          text-align: right;
          color: #757575;
          padding-right: 20px;
        }

        .badge {
          padding: 4px 8px;
        }
      }
    }
  }

  &__btns {
    white-space: nowrap;
    text-align: right;
  }

  &__toggle {
    color: $gray-800;
    text-decoration: underline;
    font-size: 16px;
    white-space: nowrap;

    &:hover {
      color: $primary;
      text-decoration: underline;
    }

    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  &__btn {
    display: inline-block;
    font-size: 14px;
    padding: 0 20px;
    line-height: 33px;
    border-radius: 2px;
    border: 1px solid $border-color;
    color: $gray-600;
    transition: .3s;
    white-space: nowrap;

    &:hover {
      color: $white;
      background-color: $primary;
      border-color: $primary;
      text-decoration: none;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;

    &__info {
      margin-right: 0;
      margin-bottom: 16px;
    }

    &__btns {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.announcement {
  padding: 20px 30px;

  &:hover,
  &.read {
    background-color: #f8f8f8;
  }

  &__frame {
    @include frame('rect', 100%);
    width: 80px;
    margin-right: 20px;
  }

  &__title {
    font-size: 14px;
    margin-bottom: 6px;
  }

  &__time {
    color: #757575;
  }

  &__desc {
    @include max-line(3);
  }

  &__btn {
    margin-left: 20px;
  }
}

.avatar-input {
  &__preview {
    @include frame('circle');
    border-radius: 50%;
    overflow: hidden;
    width: 100px;
    max-width: 100%;
    margin: 0 auto 20px;
    background: url('../images/avatar-placeholder.png') center/cover no-repeat;
  }

  &__label {
    display: flex;
    justify-content: center;

    label {
      display: inline-block;
      position: relative;
      cursor: pointer;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
    }

    span {
      display: inline-block;
      font-size: 14px;
      line-height: 38px;
      border-radius: 2;
      border: 1px solid $border-color;
      padding: 0 20px;
    }
  }

  &__text {
    margin-top: 4px;
    color: #999;
  }
}



.order {
  background-color: $white;
  padding: 30px 30px 0;
  border-radius: 4px;
  box-shadow: 0 1px 10px rgba(#000, 0.03);

  &__title {
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    color: $primary;
    margin-bottom: 20px;
  }

  &__block {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: $border-color;
    border-radius: 4px 4px 0 0;
    padding: 10px;
    text-align: center;
    margin-bottom: 0;
  }

  &__label {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  &__body {
    border: 1px solid $border-color;
    border-top: 0;
    padding: 20px 20px 16px;

    &:last-child {
      border-radius: 0 0 4px 4px;
    }
  }

  &__info {
    &--expand {
      display: none;
      padding-top: 20px;
    }
  }

  &__toggle {
    border-top: 1px solid $border-color;
    padding: 16px 0 0;
  }

  &__btn {
    display: block;
    width: 100%;
    border-radius: 4px;
    background: $gradient;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    color: $white;
    border: 0;
    line-height: 35px;
  }

  &__discount {
    .input-group {
      .form-control {
        border-radius: 4px;
        margin-right: 10px;
      }

      .input-group-text {
        border-radius: 4px;
        background: $gradient;
        font-weight: 700;
        color: #fff;
        border: 0;
      }
    }
  }

  &__price {
    white-space: nowrap;
    font-size: 14px;
    font-weight: 700;
    color: $danger;
  }

  &__extend {
    display: flex;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    padding: 4px 20px;
    border: 1px solid $border-color;
    border-top: 0;

    &:last-child {
      border-radius: 0 0 4px 4px;
    }

    &--label {
      color: #999;
    }

    .form-control,
    .custom-select {
      height: 30px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  @media (max-width: 767px) {
    padding: 20px 10px 0;

    &__title {
      padding: 12px;
      font-size: 20px;
      margin-bottom: 10px;
    }

    &__body {
      padding: 12px;
    }

    &__extend {
      padding: 4px 12px;
    }
  }
}

.order-product {
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &__frame {
    @include frame('rect', 100%);
    @include frame('zoom');
    width: 80px;
    margin-right: 10px;
  }

  &__title {
    font-size: 14px;
    margin-right: 10px;
  }

  &__price {
    font-size: 14px;
    font-weight: 700;
    color: $danger;
    text-align: right;
  }
}


.order-progress {
  display: flex;
  flex-wrap: wrap;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    position: relative;
    z-index: 1;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 50%;
      height: 2px;
      position: absolute;
      top: 29px;
      left: 0;
      background-color: $primary;
      z-index: -1;
    }

    &::after {
      left: 50%;
    }

    &:first-child::before {
      display: none;
    }

    &:last-child::after {
      display: none;
    }

    &.active::after {
      background-color: #ccc;
    }

    &.active~& {

      &::before,
      &::after {
        background-color: #ccc;
      }

      .order-progress__icon {
        border-color: #ccc;
        color: #ccc;
      }
    }
  }

  &__icon {
    display: flex;
    width: 60px;
    height: 60px;
    border: 2px solid $primary;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    color: $primary;
    margin: 0 auto;
    background-color: $white;

    &:hover {
      text-decoration: none;
    }
  }

  &__text {
    text-align: center;
    margin-top: 8px;
    font-size: 13px;
    padding: 0 5px;
  }

  @media (max-width: 767px) {
    &__item {

      &::before,
      &::after {
        top: 23px;
      }
    }

    &__icon {
      width: 45px;
      height: 45px;
      font-size: 16px;
      border-width: 1px;
    }

    &__text {
      font-size: 12px;
      padding: 0 3px;
    }
  }
}
